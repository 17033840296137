import React from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';

const UserCard = ({ img, activiteit, link, naam, kantoor }) => (
  <CardWrapper>
    <ImgWrapper alt={img} img={img} />
    <TextWrapper>
      <Activiteit>{activiteit}</Activiteit>
      <UserName>{naam}</UserName>
      <Kantoor>{kantoor}</Kantoor>
      <ButtonWrapper>
        <a href={link} target="_blank" rel="noopener noreferrer">
          <Linkbutton>
            Bekijk website <FontAwesomeIcon icon={faExternalLinkAlt} />
          </Linkbutton>
        </a>
      </ButtonWrapper>
    </TextWrapper>
  </CardWrapper>
);

const CardWrapper = styled.div`
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0 4px 6px 0 rgba(182, 182, 182, 0.4);
  transition: all 0.2s ease-in-out;
`;

const ImgWrapper = styled.div`
  background-image: url(${(props) => props.img});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 4px 4px 0 0;
  clip-path: polygon(0 0, 100% 0, 100% 86%, 0% 100%);
  height: 250px;
  max-height: 250px;
  min-height: 250px;
`;

const TextWrapper = styled.div`
  padding: 10px 20px;
`;

const UserName = styled.h3`
  padding: 30px 0 0 0;
  font-style: italic;
`;

const Kantoor = styled.p`
  color: #929292;
`;

const Activiteit = styled.h2`
  color: #d26426;
  min-height: 80px;
`;
const ButtonWrapper = styled.div`
  margin-top: 30px;
  text-align: right;
`;
const Linkbutton = styled.button`
  background: rgba(0, 0, 0, 0);
  border-radius: 20px;
  border: 0px solid #fff;
  padding: 7px 12px;
  color: #d26426;
  margin-right: -12px;
  transition: all 0.2s ease-in-out;

  &:hover {
    background: rgba(210, 100, 38, 0.4);
    cursor: pointer;
    transition: all 0.2s ease-in-out;
  }
`;

export default UserCard;
