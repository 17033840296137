import React from 'react';
import GlobalHead from '../config/GlobalHead';
import GlobalStyle from '../config/GlobalStyle';
import { Provider } from '../contexts/context';
import Footer from '../modules/footer/Footer';
import Header from '../modules/navigation/Header';
import RelationsWrapper from '../modules/relations/Relations';

const AlgemeneVoorwaarden = () => (
  <Provider>
    <GlobalStyle />
    <GlobalHead />
    <Header />
    <RelationsWrapper />
    <Footer />
  </Provider>
);

export default AlgemeneVoorwaarden;
