import { Box, Flex } from '@rebass/grid';
import React from 'react';
import styled from 'styled-components';
import HansRiederImg from '../../assets/images/hans-rieder.jpg';
import MichelMausImg from '../../assets/images/michel-maus.jpg';
import ErikaRentmeestersImg from '../../assets/images/erika-rentmeesters.jpg';
import PaulMaeyaertImg from '../../assets/images/paul-maeyaert.jpg';
import UserCard from './UserCard';

const Home = () => (
  <Flex justifyContent="center" p={3}>
    <RelationsWrapper>
      <Flex justifyContent="center">
        <Styledh1>Niche-relaties</Styledh1>
      </Flex>
      <QuoteWrapper>
        <p>In der Beschränkung zeigt sich erst der Meister, und das Gesetz nur kann uns Freiheit geben.</p>
        <Author>- Johann Wolfgang von Goethe (1749-1832)</Author>
      </QuoteWrapper>
      <TextBlock>REYNAERT ADVOCATEN concentreert zich op zijn kernactiviteiten en voorkeurmateries.</TextBlock>
      <TextBlock>
        Dat mag echter niet betekenen dat aan het clientèle geen integrale service of advies kan worden gegeven over de wijze van
        aanpak.
      </TextBlock>
      <TextBlock>
        De door de cliënt voorgelegde bezorgdheden, en daaropvolgende aanpak van problemen kunnen immers altijd vanuit REYNAERT
        ADVOCATEN worden aangestuurd. In het kader van de uitvoering van die opdracht behoudt het kantoor zich het recht voor te
        beseffen dat kantoren die in bepaalde niche-segmenten veel beter geschikt zijn dan het eigen kantoor.
      </TextBlock>
      <TextBlock>
        REYNAERT ADVOCATEN kan dan ook in bepaalde materies zijn cliënt doorverwijzen naar andere kantoren waarbij haar rol er
        zich enkel en alleen toe beperkt te zorgen voor een introductie bij de hieronder vermelde confraters en daar de
        tussenkomst dan stopt.
      </TextBlock>
      <TextBlock>
        Per definitie is dat advies voor de cliënt compleet vrijblijvend en is het enkel ingegeven door de ervaring en het
        vertrouwen dat REYNAERT ADVOCATEN zelf stelt in deze confraters.
      </TextBlock>
      <TextBlock>
        De eventuele samenwerking tussen cliënt en het kantoor waarnaar door verwezen wordt is exclusief een contractuele zaak
        tussen cliënt en dat kantoor.
      </TextBlock>
      <br />
      <TextBlock>Deze doorverwijzing zal in o.a. hiernavolgende materies gebeuren :</TextBlock>

      <CardsFlex flexWrap="wrap" justifyContent="center">
        <Box width={[1, 1 / 2]} p={3}>
          <UserCard
            activiteit={'Algemeen strafrecht en strafprocedure'}
            img={HansRiederImg}
            link={'https://www.rieder-verdonck.be/'}
            naam={'Mtr. Hans RIEDER'}
            kantoor={'Rieder & Verdonck advocaten'}
          />
        </Box>

        <Box width={[1, 1 / 2]} p={3}>
          <UserCard
            activiteit={'Fiscaal recht en fiscaal frauderecht'}
            img={MichelMausImg}
            link={'http://www.bloom-law.be/nl/ons-advocatenkantoor/advocaten/13/michel-mau'}
            naam={'Prof. Dr. Michel MAUS'}
            kantoor={'Bloom law firm'}
          />
        </Box>

        <Box width={[1, 1 / 2]} p={3}>
          <UserCard
            activiteit={'Omgevingsrecht en administratief recht'}
            img={ErikaRentmeestersImg}
            link={'http://www.truyensadvocaten.be'}
            naam={'Mtr. Erika RENTMEESTERS'}
            kantoor={'Truyens advocaten'}
          />
        </Box>

        <Box width={[1, 1 / 2]} p={3}>
          <UserCard
            activiteit={'Intellectueel eigendom & geschillenbeslechting'}
            img={PaulMaeyaertImg}
            link={'https://fencerlaw.eu/paul-maeyaert/'}
            naam={'Mtr. Paul MAEYAERT'}
            kantoor={'Fencer law'}
          />
        </Box>
      </CardsFlex>
    </RelationsWrapper>
  </Flex>
);

export default Home;

const RelationsWrapper = styled(Box)`
  max-width: 1128px;
`;

const CardsFlex = styled(Flex)`
  max-width: 800px;
  margin: 0 auto;
`;

const Styledh1 = styled.h1`
  color: #d26426;
`;

const QuoteWrapper = styled.div`
  text-align: center;
  margin-bottom: 50px;
`;

const Author = styled.p`
  color: grey;
`;

const TextBlock = styled.p`
  margin-top: 10px;
  margin-bottom: 10px;
`;
